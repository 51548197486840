@import 'breakpoint';
@import 'typography';
@import 'functions';

html {
    min-height: 100%;
    background-color: var(--pale-grey-25-color);
}

body {
    overflow-y: auto;
    font-family: var(--os-typography-font-default-font-family);
    margin: 0;
    overflow-x: hidden;
    color: var(--medium-grey-color);
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    .sbgSnackBar {
        @extend %m-medium-white;
        max-width: rem-calc(600);
        --mdc-snackbar-container-shape: 8px;
        --mat-mdc-snack-bar-button-color: var(--white-color);
        --mdc-snackbar-supporting-text-color: var(--white-color);
        --mdc-snackbar-supporting-text-weight: var(--os-typography-weight-medium-font-weight);
        --mdc-snackbar-supporting-text-size: var(--os-typography-size-m-font-size);
        --mat-snack-bar-button-color: var(--white-color);

        &.snackBarSuccess {
            --mdc-snackbar-container-color: var(--positive-color);
        }
        &.snackBarError {
            --mdc-snackbar-container-color: var(--negative-color);
        }
        &.snackBarNeutral {
            --mdc-snackbar-container-color: var(--medium-grey-color);
        }
        &.snackBarTopRight {
            position: relative;
            top: 120px;
        }
        .mat-simple-snackbar-action {
            @extend %m-medium-white;
        }
    }

    .mat-drawer-container {
        background-color: var(--pale-grey-50-color);
    }

    mat-sidenav-content {
        background-color: var(--pale-grey-50-color);
    }

    .action {
        color: var(--primary-color);
        font-weight: var(--os-typography-weight-medium-font-weight);
    }
}

.full-width-when-small {
    @include breakpoint(small_only) {
        width: 100%;
    }
    button {
        @include breakpoint(small_only) {
            width: 100%;
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.animated-background {
    position: relative;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 16px;
    height: 16px;
}

mat-icon {
    svg {
        pointer-events: none;
    }
}
sbg-button-ng {
    margin: 0 4px;
}

sbg-button {
    margin: 0 4px;
}

.avoid-clicks {
    pointer-events: none;
}

.mat-select-arrow {
    color: var(--primary-color);
}

.t-and-cs-form-hint {
    margin-top: 3px;
    text-align: left;

    @include breakpoint(small_only) {
        margin-bottom: 16px;
    }
}

.t-and-cs-settings-hint {
    margin-bottom: 8px;
    text-align: left;
}

.t-and-cs-secure-hint {
    margin-bottom: 3px;
    text-align: left;

    @include breakpoint(small_only) {
        text-align: center;
    }
}

.orange-tooltip {
    background: var(--extended-03-color);
    @extend %xs-regular-darkgrey;
    padding: 16px !important;
}

.hidden {
    display: none !important;
}

.cdk-overlay-container:has(.no-fullscreen-overlay) {
    z-index: 1;
}

.mat-dialog-values-on-create {
    max-width: 1050px !important;

    @include breakpoint(tablet) {
        max-width: 412px !important;
    }

    @include breakpoint(mobile) {
        max-width: 100% !important;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.a11y-unstyled-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.a11y-unstyled-button:focus {
    outline: none;
}
