.coach-mark-background {
    &::before {
        content: '';
        z-index: 999;
        position: absolute;
        width: 100vw;
        height: 200vh;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        left: 0;
    }
}

.disable-scroll {
    overflow: hidden;
}
