mat-chip {
    padding: 4px;
    border: 1px solid var(--secondary-color);
    background-color: var(--white-color) !important;
    &.mat-chip-selected {
        border: 1px solid var(--secondary-color);
        background-color: var(--secondary-color) !important;
        mat-icon {
            color: var(--white-color) !important;
        }
    }
    mat-icon {
        color: var(--secondary-color) !important;
        margin-right: 0.5rem;
    }
}

.mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-label-text-color: var(--secondary-color);
    &.mat-chip-selected {
        --mdc-chip-label-text-color: var(--white-color);
    }
}
