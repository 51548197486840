@import 'breakpoint';
//Scope global material styles to specific components
.sbg-custom-stepper {
    .mat-select-arrow-wrapper {
        padding: 4px;
    }

    .card-body,
    .card-title {
        .mat-form-field-type-sbg-posh-select,
        .mat-form-field-type-sbg-account-select {
            &.ng-valid {
                .mat-form-field-underline {
                    display: none;
                }

                .mat-form-field-infix {
                    padding: 0;
                }

                .mat-form-field-wrapper {
                    padding: 0;
                }
            }

            &.mat-form-field-disabled {
                .mat-form-field-underline {
                    display: none;
                }

                .mat-form-field-infix {
                    padding: 0;
                }

                .mat-form-field-wrapper {
                    padding: 0;
                }
            }

            mat-error {
                @extend %xxs-regular-negative;
            }
        }
    }

    .mat-horizontal-stepper-header {
        pointer-events: none !important;
    }

    @include breakpoint(small_only) {
        .card-content {
            border-radius: 0;
        }
        .card-footer {
            border-radius: 0 !important;
            box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.18);
        }
    }

    mat-horizontal-stepper {
        .mat-step-header {
            height: 48px;

            .mat-step-label {
                color: var(--dark-grey-color);
            }
        }

        .mat-horizontal-content-container {
            background-color: var(--pale-grey-50-color);
            box-shadow: inset 0 15px 15px -15px rgba(0, 0, 0, 0.5);
            padding: 0;
        }

        .mat-horizontal-stepper-content {
            margin-left: auto;
            margin-right: auto;
        }

        .mat-horizontal-stepper-header-container {
            margin: auto;

            @include breakpoint(small_only) {
                display: none;
            }
        }
    }

    &.four-steps {
        .mat-horizontal-stepper-header-container {
            max-width: 755px;
        }

        .mat-horizontal-stepper-content {
            max-width: 909px;
        }
    }

    &.three-steps {
        .mat-horizontal-stepper-header-container {
            max-width: 585px;
        }

        .mat-horizontal-stepper-content {
            max-width: 909px;
        }
    }

    &.two-steps {
        .mat-horizontal-stepper-header-container {
            max-width: 415px;
        }

        .mat-horizontal-stepper-content {
            max-width: 909px;
        }
    }

    &.quick-steps {
        .mat-horizontal-stepper-header-container {
            display: none;
        }

        .mat-horizontal-content-container {
            box-shadow: none;
            padding: 0 24px 24px 24px;

            @include breakpoint(small_only) {
                padding: 0px;
            }
        }
    }

    &.hide-steps {
        .mat-horizontal-stepper-header-container {
            display: none;
        }

        .mat-horizontal-content-container {
            box-shadow: none;
        }
    }

    &.flat-stepper {
        .mat-stepper-horizontal {
            background-color: var(--pale-grey-25-color);
        }

        .mat-horizontal-stepper-header-container {
            padding-top: 2rem;
        }

        .mat-horizontal-content-container {
            box-shadow: none;
            background-color: var(--pale-grey-25-color);
        }
    }
}
