/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: var(--primary-color);
    --ion-color-primary-rgb: 0, 51, 170;
    --ion-color-primary-contrast: var(--white-color);
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #002d96;
    --ion-color-primary-tint: #1a47b3;

    /** secondary **/
    --ion-color-secondary: var(--secondary-color);
    --ion-color-secondary-rgb: 0, 98, 225;
    --ion-color-secondary-contrast: var(--white-color);
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #2d5ab4;
    --ion-color-secondary-tint: #4775d1;

    /** tertiary **/
    --ion-color-tertiary: #0089ff;
    --ion-color-tertiary-rgb: 0, 170, 221;
    --ion-color-tertiary-contrast: var(--white-color);
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #0096c2;
    --ion-color-tertiary-tint: #1ab3e0;

    /** success **/
    --ion-color-success: var(--positive-color);
    --ion-color-success-rgb: 14, 138, 0;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0c7900;
    --ion-color-success-tint: #26961a;

    /** warning **/
    --ion-color-warning: var(--warning-color);
    --ion-color-warning-rgb: 255, 184, 34;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0a21e;
    --ion-color-warning-tint: #ffbf38;

    /** danger **/
    --ion-color-danger: var(--negative-color);
    --ion-color-danger-rgb: 220, 10, 10;
    --ion-color-danger-contrast: var(--white-color);
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #c5000d;
    --ion-color-danger-tint: #e31a27;

    /** dark **/
    --ion-color-dark: var(--dark-grey-color);
    --ion-color-dark-rgb: 2, 7, 13;
    --ion-color-dark-contrast: var(--white-color);
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e1e1e;
    --ion-color-dark-tint: #383838;

    /** medium **/
    --ion-color-medium: var(--medium-grey-color);
    --ion-color-medium-rgb: 26, 49, 77;
    --ion-color-medium-contrast: var(--white-color);
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #3c3c3c;
    --ion-color-medium-tint: #575757;

    /** light **/
    --ion-color-light: var(--light-grey-color);
    --ion-color-light-rgb: 92, 108, 128;
    --ion-color-light-contrast: var(--white-color);
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #636363;
    --ion-color-light-tint: #7e7e7e;

    /** secondary button **/
    --ion-color-btnsecondary: transparent;
    --ion-color-btnsecondary-rgb: 255, 215, 0;
    --ion-color-btnsecondary-contrast: var(--secondary-color);
    --ion-color-btnsecondary-contrast-rgb: 255, 255, 255;
    --ion-color-btnsecondary-shade: var(--pale-grey-50-color);
    --ion-color-btnsecondary-tint: var(--pale-grey-50-color);

    /** inverse outline button **/
    --ion-color-inverse: var(--white-color);
    --ion-color-inverse-rgb: 255, 215, 0;
    --ion-color-inverse-contrast: var(--secondary-color);
    --ion-color-inverse-contrast-rgb: 255, 255, 255;
    --ion-color-inverse-shade: var(--pale-grey-50-color);
    --ion-color-inverse-tint: var(--pale-grey-50-color);

    /** tertiary button **/
    --ion-color-btntertiary: var(--pale-grey-50-color);
    --ion-color-btntertiary-rgb: 255, 215, 0;
    --ion-color-btnsecondary-contrast: var(--primary-color);
    --ion-color-btnsecondary-contrast-rgb: 255, 255, 255;
    --ion-color-btnsecondary-shade: var(--pale-grey-50-color);
    --ion-color-btnsecondary-tint: var(--pale-grey-50-color);

    --ion-font-family: var(--os-typography-font-default-font-family);
}
