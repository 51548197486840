.mdc-tab__text-label:not(.mat-mdc-tab-disabled) {
    @extend %m-medium-darkgrey;
}

.mat-mdc-tab-group {
    border-radius: 16px;
    border: 1px solid var(--pale-grey-color);
    margin-top: 20px;
    background-color: var(--white-color);
    margin-bottom: 20px;

    @include breakpoint(mobile) {
        margin-left: 8px;
        margin-right: 8px;
        .mat-mdc-tab.mdc-tab {
            padding-right: 0px;
            padding-left: 10px;
        }
    }

    .mat-mdc-tab-header {
        border-bottom: 1px solid var(--pale-grey-color);
        border-radius: 16px 16px 0 0;
    }
    .mat-mdc-tab.mdc-tab {
        height: 54px;
    }

    &.one-tab-at-a-time-in-mobile {
        @include breakpoint(mobile) {
            .mat-mdc-tab.mdc-tab {
                min-width: calc(100vw - 88px);
                padding: 0;
            }
        }
    }
}
