@import 'gradient';

$primary-color: #0033aa;
$secondary-color: #0089ff;
$positive-color: #0e8a00;
$accent-color: #ff6611;
$notification-color: #ffb822;
$identifier-color: #b34fc5;
$disabled-color: #949494;
$closed-color: #1a314d;

.sbg-icon {
    display: flex;
    justify-content: center;
    align-items: center !important;
    border-radius: 50%;
    width: 48px;
    height: 48px;

    &.sbg-gradient {
        &-primary {
            @include sbg-gradient($primary-color);
            color: var(--white-color);
        }

        &-secondary {
            @include sbg-gradient($secondary-color);
            color: var(--white-color);
        }

        &-identifier {
            @include sbg-gradient($identifier-color);
            color: var(--white-color);
        }

        &-positive {
            @include sbg-gradient($positive-color);
            color: var(--white-color);
        }

        &-accent {
            @include sbg-gradient($accent-color);
            color: var(--white-color);
        }

        &-notification {
            @include sbg-gradient($notification-color);
            color: var(--white-color);
        }

        &-disabled {
            @include sbg-gradient($disabled-color);
            color: var(--white-color);
        }

        &-closed {
            @include sbg-gradient($closed-color);
            color: var(--white-color);
        }
    }
}
