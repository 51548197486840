sbg-dialog {
    sbg-chip-ng {
        mat-chip-listbox {
            padding: 0 0 0.25rem 0.25rem;

            mat-chip-option {
                span {
                    color: var(--secondary-color);
                }

                &:hover {
                    span {
                        color: var(--white-color);
                    }
                }
            }
        }
    }
}
