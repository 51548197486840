@mixin breakpoint($point) {
    @if $point == xxlarge {
        @media (min-width: 1920px) {
            @content;
        }
    } @else if $point == xlarge {
        @media (min-width: 1440px) {
            @content;
        }
    } @else if $point == large {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $point == large_only {
        @media (max-width: 1440px) {
            @content;
        }
    } @else if $point == medium {
        @media (min-width: 640px) {
            @content;
        }
    } @else if $point == medium_only {
        @media (max-width: 1200px) {
            @content;
        }
    } @else if $point == small {
        @media (min-width: 0px) {
            @content;
        }
    } @else if $point == small_tablet {
        @media (max-width: 560px) {
            @content;
        }
    } @else if $point == small_only {
        @media (max-width: 640px) {
            @content;
        }
    } @else if $point == tablet {
        @media (max-width: 768px) {
            @content;
        }
    } @else if $point == mobile {
        @media (max-width: 480px) {
            @content;
        }
    } @else if $point == desktop {
        @media (min-width: 769px) {
            @content;
        }
    } @else if $point == small_desktop {
        @media (max-width: 1024px) {
            @content;
        }
    } @else if $point == tablet_only {
        @media (max-width: 768px) and (min-width: 480px) {
            @content;
        }
    }
}
