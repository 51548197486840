form {
    .mat-mdc-form-field-underline {
        background-color: var(--medium-grey-color);
    }

    .mat-mdc-form-field {
        padding-bottom: 16px;
        width: 100%;
    }

    .mat-mdc-form-field-placeholder {
        @extend %s-regular-darkgrey;
    }

    .mat-mdc-focused .mat-mdc-form-field-placeholder {
        color: var(--primary-color) !important;

        &.mat-accent {
            color: var(--accent-color);
        }

        &.mat-warn {
            color: var(--negative-color) !important;
        }
    }

    .mat-mdc-form-field-can-float {
        &.mat-form-field-should-float .mat-form-field-placeholder {
            @extend %xs-regular-darkgrey;
            font-size: rem-calc(16);
        }
    }

    .mat-mdc-form-field-ripple {
        background-color: var(--primary-color);

        &.mat-accent {
            background-color: var(--warning-color);
        }

        &.mat-warn {
            background-color: var(--warning-color);
        }
    }

    .mat-mdc-form-field-invalid {
        .mat-mdc-form-field-placeholder {
            color: var(--negative-color);

            &.mat-accent,
            .mat-form-field-required-marker {
                color: var(--negative-color);
            }
        }

        .mat-mdc-form-field-ripple {
            background-color: var(--negative-color);
        }
    }

    .mat-error {
        color: var(--negative-color);
    }

    .mat-form-field-disabled {
        mat-label {
            color: var(--mdc-outlined-text-field-disabled-input-text-color);
        }
    }
}

.mat-mdc-form-field-type-sbg-account-select {
    .mat-mdc-text-field-wrapper {
        background-color: var(--white-color);
        border-radius: 4px;
        min-width: 275px;
    }
}
