.mat-mdc-paginator {
    .mat-mdc-paginator-range-label,
    .mat-mdc-paginator-page-size-label {
        color: var(--medium-grey-color);
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    .mat-mdc-paginator-page-size {
        align-items: center;
    }

    .mat-mdc-select-arrow svg,
    .mat-mdc-paginator-icon {
        fill: var(--secondary-color);
    }

    .mat-mdc-paginator-range-label {
        margin: 0 16px;
    }

    .mat-mdc-paginator-range-label,
    .mat-mdc-select-value {
        font-size: 12px;
    }

    @include breakpoint(mobile) {
        .mat-mdc-paginator-page-size-label,
        .mat-mdc-paginator-range-label {
            margin: 0;
            text-wrap: nowrap;
        }

        .mat-mdc-paginator-range-label {
            margin: 0 8px;
        }

        .mat-mdc-paginator-container {
            flex-wrap: nowrap;
            padding: 0;
        }

        .mat-mdc-button-base {
            --mdc-icon-button-state-layer-size: 32px;
            padding: 4px;
        }
    }
}
