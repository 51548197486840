.sbg-help-and-services-dialog__content__sbg-row__detailed-cards-options {
    sbg-carousel-component {
        .carousel {
            .carousel-item {
                padding-left: 0.25rem;
                float: left;
            }
        }
    }
}

.sbg-help-and-services-dialog__content__sbg-row__detailed-cards-options__account {
    sbg-carousel-component {
        .carousel {
            .carousel-item {
                padding-left: 0.25rem;
                float: left;
            }
        }
    }
}
