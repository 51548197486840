mat-datepicker-toggle,
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
    color: var(--secondary-color);
    vertical-align: super;
}

.mat-calendar-body-disabled {
    color: var(--light-grey-color);
}

datepicker-custom-header {
    .mdc-icon-button {
        display: flex;
        align-items: center;
    }
    .mat-mdc-icon-button svg,
    .mat-mdc-icon-button img {
        width: 14px;
        height: 14px;
    }
}
