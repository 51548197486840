.sbsa-mat-table {
    &.with-border {
        border: solid 1px var(--pale-grey-color);
        border-radius: 16px;
    }

    &.with-bottom-border {
        border-radius: 0 0 16px 16px;
    }

    &.no-cell-background {
        .mat-mdc-cell {
            background: none;
        }
    }

    background-color: var(--white-color);
    overflow: hidden;

    .x-small-col {
        flex: 0.5 1 0 !important;
    }
    .small-col {
        flex: 1 1 0 !important;
    }

    .med-col {
        flex: 2 1 0 !important;
    }

    .large-col {
        flex: 3 1 0 !important;
    }

    .x-large-col {
        flex: 4 1 0 !important;
    }

    .right-align {
        justify-content: flex-end;
    }

    .center-align {
        justify-content: center;
    }

    .mat-paginator {
        background: none;
    }

    .mat-mdc-table {
        background: none;
    }

    .mat-mdc-footer-row {
        height: auto;
    }

    .mat-mdc-header-cell:first-of-type,
    .mat-mdc-cell:first-of-type {
        padding-left: 24px;
    }

    .mat-mdc-header-cell:last-of-type,
    .mat-mdc-cell:last-of-type {
        padding-right: 24px;
    }

    .mat-mdc-cell {
        border-color: var(--pale-grey-color);
    }

    .mdc-data-table__row:last-child .mdc-data-table__cell {
        border-bottom: solid 1px var(--pale-grey-color);
    }

    .mat-mdc-header-row {
        background-color: var(--pale-grey-25-color);
        border: 0;

        .mat-mdc-header-cell {
            @extend %s-medium-darkgrey;
            border: 0;
        }
    }
    .mat-sort-header-content {
        text-align: left;
    }

    .no-border-and-underline {
        box-shadow: none;
        border-radius: unset;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: var(--pale-grey-color);
        margin-bottom: 24px;
        .mat-mdc-header-cell {
            @extend %m-medium-darkgrey;
            border-bottom: none;
        }
        .mat-mdc-cell {
            border-bottom: none;
        }
        .mat-mdc-header-cell:last-of-type,
        .mat-mdc-cell:last-of-type {
            text-align: right;
        }
        .mat-mdc-header-row,
        .mat-mdc-row {
            height: 40px;
        }
    }

    &.left-and-right-border {
        .mat-mdc-cell {
            border-bottom: none !important;
        }
        .mat-mdc-row {
            border: 1px solid var(--pale-grey-color);
            border-top: 0;
        }
        .mat-mdc-row:hover {
            background-color: var(--white-color);
        }
    }

    &.remove-hover-and-cell-underline {
        .mat-mdc-row:hover {
            background-color: var(--white-color);
        }
        .mdc-data-table__cell {
            border-bottom: 0 !important;
        }
    }

    &.no-border-on-last-row {
        .mdc-data-table__row:last-child .mdc-data-table__cell {
            border-bottom: 0;
        }
    }

    &.no-border-on-last-row {
        .mdc-data-table__row:last-child .mdc-data-table__cell {
            border-bottom: 0;
        }
    }

    .table-cell {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            word-wrap: break-word;
            white-space: pre-wrap;
        }
    }

    .style-alphabetic-sort {
        color: var(--secondary-color);
        margin-left: 3px;
    }

    .mat-sort-header-arrow {
        display: none !important;
    }
}
