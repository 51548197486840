@use 'variables';

ion-content {
    --ion-background-color: var(--white-color);
}

.ion-color-btnsecondary {
    --ion-color-base: var(--ion-color-btnsecondary);
    --ion-color-base-rgb: var(--ion-color-btnsecondary-rgb);
    --ion-color-contrast: var(--ion-color-btnsecondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-btnsecondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-btnsecondary-shade);
    --ion-color-tint: var(--ion-color-btnsecondary-tint);
}

.ion-color-btntertiary {
    --ion-color-base: var(--ion-color-btntertiary);
    --ion-color-base-rgb: var(--ion-color-btntertiary-rgb);
    --ion-color-contrast: var(--ion-color-btntertiary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-btntertiary-contrast-rgb);
    --ion-color-shade: var(--ion-color-btntertiary-shade);
    --ion-color-tint: var(--ion-color-btntertiary-tint);
}

ion-button {
    --border-radius: 5px;
    --box-shadow: none;
    text-transform: uppercase;
    height: 36px;

    &.small {
        --padding-start: 5px;
        --padding-end: 5px;
    }
}

ion-item {
    --background: transparent;
    --background-focused: transparent;
    --highlight-height: 2px;
    --highlight-color-valid: var(--ion-color-primary);
    --highlight-color-invalid: var(--ion-color-primary);
    --color: var(--light-grey-color);

    // Ionic does not change color for the floating label for iOS
    &.item-has-focus {
        &.ion-untouched,
        &.ion-valid {
            > ion-label {
                color: var(--ion-color-primary);
            }
        }
    }

    ion-label.label-floating.label-floating {
        --color: var(--ion-color-light);
        transform: translate3d(0, 156%, 0);
    }

    // Everything below is to prevent invalid styles from showing until the fields are interacted with
    &.ion-touched {
        --highlight-color-invalid: var(--ion-color-danger);
    }

    &.ion-invalid {
        &.ion-untouched {
            color: var(--ion-color-light);
        }

        &.ion-touched .label-floating.label-floating {
            color: var(--ion-color-danger);
        }
    }

    .ion-touched {
        ion-label {
            &.validation-message {
                display: block;
            }
        }
    }

    .ion-untouched {
        ion-label {
            &.validation-message {
                display: none;
            }
        }
    }
}

// error-tailor specific check to show invalid styles when form submitted
form {
    --highlight-color-invalid: var(--ion-color-primary);

    &.form-submitted {
        ion-item {
            --highlight-color-invalid: var(--ion-color-danger);

            &.ion-invalid {
                .label-floating.label-floating {
                    color: var(--ion-color-danger);
                }
            }
        }
    }
}

ion-content {
    --background: transparent !important;
}

ion-toast.md {
    --border-radius: 0;
    --start: 0;
    --end: 0;
}

ion-toast.ios.ion-color-danger {
    --border-radius: 5px;
    --ion-color-contrast: var(--negative-color) !important;
    --ion-color-base: var(--pale-grey-50-color) !important;
}

ion-toolbar {
    --min-height: 60px;
    --padding-start: var(--SMALL-MARGIN);
    --padding-end: var(--SMALL-MARGIN);

    // not using typography names as we want ionic to take care of the font color dependant on the toolbar background
    .title-default {
        font-size: 20px;
    }

    .title-small {
        font-size: 18px;
        &.ios {
            padding: 0; // default ion padding makes the title off-centre 🤷
        }
    }
}

ion-icon {
    width: 24px;
    height: 24px;
}

/*  code needed, because of ionic v7
    https://github.com/ionic-team/ionic-framework/blob/6ffbdbb3b2b69290cf25753d535bc7483bd7c6e8/BREAKING.md#css-utilities
*/
[hidden] {
    display: none !important;
}
