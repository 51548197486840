@import 'breakpoint';

sbg-table {
    .table-row-container {
        display: flex;
        flex: auto;
        align-items: center;
    }

    .table-header {
        padding-right: 1.5rem;
    }

    .table-cell {
        padding-right: 1.5rem;

        &.less-padding {
            padding-right: 0.8rem;
        }
    }

    .x-small-col {
        flex: 0.5 1 0 !important;
    }

    .small-col {
        flex: 1 1 0 !important;
    }

    .med-col {
        flex: 2 1 0 !important;
    }

    .large-col {
        flex: 3 1 0 !important;
    }

    .x-large-col {
        flex: 4 1 0 !important;
    }

    .no-padding {
        padding: 0;
    }

    .right-align {
        text-align: right;
    }

    .sbg-table-filter {
        width: 100%;
        background-color: var(--white-color);
    }

    .transaction-date,
    .value-date {
        max-width: 5rem;
        min-width: 5rem;
        padding-right: 1.5rem;
        white-space: nowrap;
    }

    .ref-number {
        max-width: 10rem;
    }

    .amount-value {
        max-width: 90px;
        min-width: 90px;
        text-align: right;
        @include breakpoint(large) {
            padding-right: 1rem;
        }
    }

    .amount {
        padding-right: 1rem;

        &.pad-right {
            padding-right: 2rem;
        }
    }

    .amount-right-align {
        max-width: 90px;
        min-width: 90px;
        text-align: right;
    }

    .running-balance {
        max-width: 90px;
        min-width: 90px;
        text-align: right;
        @include breakpoint(large) {
            padding-right: 1rem;
            padding-left: 2rem;
        }
    }

    .download-icon {
        max-width: 2rem;
        min-width: 2rem;
        text-align: right;
        padding-right: 2rem;
        padding-left: 2rem;
        color: var(--primary-color);
    }

    .limit-to-160-px {
        min-width: 160px;
        max-width: 160px;
    }

    .customer-name-cell {
        margin-right: 8px;
    }

    .group-pending-transaction-beneficiary {
        // TODO: When API returns beneficiary nickname field then uncomment below to display it on the UI.
        // width: 10%;
        // margin-right: 1rem;
        //margin-left: 1rem;
    }

    .group-pending-transaction-reference {
        width: 12%;
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .group-pending-transaction-details {
        width: 23%;
        margin-right: 1rem;
    }

    .group-pending-transaction-type {
        width: 15%;
        margin-right: 1rem;
    }

    .group-pending-transaction-beneficiary-name {
        width: 22%;
        margin-right: 1rem;
    }

    .group-pending-transaction-account-number {
        width: 14%;
        margin-right: 1rem;
    }

    .group-pending-transaction-currency {
        width: 5%;
        margin-right: 1rem;
    }

    .group-pending-transaction-amount {
        width: 10%;
        margin-right: 1rem;
    }

    .group-pending-transaction-utils {
        width: 20%;
        max-width: 25%;
        min-width: 5%;
        margin-right: 1rem;

        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.group-pending-requests {
    .med-col {
        width: 150px;
    }
}

@include breakpoint(small_only) {
    .sbg-table-cell.beneficiary-item-right {
        max-width: fit-content;
    }

    .sbg-table-cell.beneficiary-item-center {
        display: none;
    }
}

.pending-transactions sbg-table .sbg-table-group-container {
    .row-container .sbg-table-row.sbg-table-show-row {
        padding-top: 0;
        padding-bottom: 0;

        sbg-group-pending-transactions-row .sbg-table-cell {
            margin: 0;
        }
    }

    .sbg-table-row-details-container .row-container .sbg-table-row.sbg-table-show-row {
        padding: 0;
    }
}

.customer-list sbg-table .sbg-table-group-container {
    .row-container .sbg-table-row.sbg-table-show-row {
        cursor: auto !important;

        &:hover {
            cursor: auto !important;
        }
    }
}

@media only screen and (max-width: 739px) {
    .past-transactions-table {
        .sbg-table-row {
            padding-top: 8px !important;
            padding-bottom: 8px !important;
        }

        .sbg-table-header-row {
            display: none !important;
        }

        .sbg-table-inner-row {
            display: block !important;
        }
    }
}
