.mat-simple-snackbar {
    word-break: break-word;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        word-break: break-all;
    }
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    word-break: break-word;
}
