@function gradient-color($color) {
    $r: red($color) + 100;
    $g: green($color) + 100;
    $b: blue($color) + 100;

    @if ($r > 255) {
        $r: 255;
    }

    @if ($g > 255) {
        $g: 255;
    }

    @if ($b > 255) {
        $b: 255;
    }

    @return rgb($r, $g, $b);
}

@mixin sbg-gradient($color) {
    background: linear-gradient(to right bottom, gradient-color($color), $color 66%);
}
