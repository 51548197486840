/*******************
 Main container
*******************/
@import 'breakpoint';
:root {
    --header-desktop-height: 157px;
    --header-tablet-height: 123px;
    --header-mobile-height: 123px;
    --nav-bar-height: 37px;
}

.main-container {
    position: relative;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &.disable-scroll {
        position: fixed;
    }
}

.header-section {
    position: fixed;
    width: 100%;
    z-index: 100;
}

.boxed-space {
    height: var(--header-desktop-height);
    @include breakpoint(tablet) {
        height: var(--header-tablet-height);
    }
}

.page-content {
    overflow-y: auto;
    overflow-x: hidden;
    // required because sbg-message-centre-mfe-message-centre-entry has a height of 0, the content is not visible
    // setting the height of ion-app does not help
    min-height: calc(100vh - #{var(--header-desktop-height)});
    @include breakpoint(tablet) {
        min-height: calc(100vh - #{var(--header-tablet-height)});
    }
}

ion-app {
    top: var(--header-desktop-height) !important;

    @include breakpoint(tablet) {
        top: var(--header-tablet-height) !important;
    }

    @include breakpoint(mobile) {
        top: var(--header-mobile-height) !important;
    }
}

.print-only {
    visibility: hidden !important;
    height: 0 !important;
    overflow: hidden !important;
}

@media print {
    @page {
        margin: 0.5cm;
    }
    .hide-on-print {
        display: none !important;
    }
    .hide-buttons-on-print {
        button {
            display: none !important;
        }
    }
    .print-only {
        visibility: visible !important;
        height: auto !important;
    }
}
