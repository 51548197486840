@import 'breakpoint';
.evo-custom-stepper {
    .mat-horizontal-stepper-header-container {
        display: none;
    }
    .mat-stepper-horizontal {
        background-color: var(--pale-grey-25-color);
    }
    .mat-horizontal-content-container {
        background-color: var(--pale-grey-25-color);
        padding: 0px;
    }

    &.show-overflow {
        .mat-horizontal-content-container {
            overflow: visible;
        }
    }
}
