$mini-sidebar-width: 80px;
/*******************
Main sidebar
******************/

mat-sidenav {
    // width: 240px;
    width: 17rem;
    padding-top: 0px;
}

#snav {
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);

    hr {
        border: 0px solid var(--pale-grey-50-color);
        border-bottom: 1px solid var(--pale-grey-50-color);
        margin-top: 0;
        margin-bottom: 0;
        cursor: default;
    }

    .mat-mdc-list-base {
        padding-top: 0;
        padding-bottom: 0;
    }

    .selected {
        background: var(--primary-10-color);
        border-left: 3px solid var(--secondary-color);
        color: var(--primary-color);

        mat-icon {
            color: var(--primary-color);
        }

        .dd-icon {
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
        }
    }

    .mat-mdc-list-item:first {
        .mdc-list-item__content {
            a {
                color: var(--medium-grey-color);

                mat-icon {
                    color: var(--medium-grey-color);
                }
            }
        }
    }

    .mdc-list-item:hover::before {
        background: none;
    }

    .mat-mdc-list-item {
        height: auto;
        padding: 0;

        &:hover {
            background: none;
        }

        .mdc-list-item__content {
            display: block;
            padding: 0px 0px;

            a {
                color: var(--light-grey-color);

                mat-icon {
                    color: var(--light-grey-color);
                }
            }

            .ext-link {
                text-decoration: none;
            }

            .blue-link {
                color: var(--primary-color);
                mat-icon {
                    color: var(--primary-color);
                }
            }

            .mdc-list-item__primary-text {
                letter-spacing: 0;
                overflow: visible;
            }

            &:hover {
                a:not(.relative) {
                    background: var(--primary-10-color);
                    color: var(--primary-color);

                    mat-icon {
                        color: var(--primary-color);
                    }
                }
            }
        }

        .relative:hover {
            background: var(--primary-10-color);
            color: var(--primary-color);

            mat-icon {
                color: var(--primary-color);
            }
        }

        a {
            height: 3.5rem;
            padding: 0 20px;
            align-items: center;
            display: flex;
            font-size: 15px;
            white-space: nowrap;

            mat-icon:not(.dd-icon) {
                margin-right: 10px;
            }

            mat-icon {
                color: var(--medium-grey-color);
            }

            .dd-icon {
                font-size: 16px;
                width: 16px;
                transition: 0.2s ease-in;
                margin: 5px 0 5px 5px;
                height: 16px;
            }

            &.active {
                background: var(--primary-10-color);
                border-left: 3px solid var(--secondary-color);
                color: var(--primary-color);
            }
        }
    }

    .sub-item {
        display: none;
        background-color: var(--white-color);

        a {
            padding-left: 28px;
            height: 50px;
            margin-bottom: 0px;
            color: var(--medium-grey-color);
        }
    }

    .saperator {
        cursor: text;
        font-size: 13px;
        margin-bottom: 15px;
        margin-top: 25px;
        font-weight: 500;
        white-space: nowrap;
    }

    .selected {
        .sub-item {
            display: block;
        }
    }
}

/*==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */

@media (min-width: 1024px) {
    .minisidebar #snav {
        .mat-drawer-backdrop {
            display: none;
        }

        &.mat-sidenav ~ .mat-drawer-content {
            margin-left: $mini-sidebar-width;
        }

        &.mat-sidenav {
            -webkit-transition:
                width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
                transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
            transition:
                width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
                transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
        }

        &.mat-sidenav:not(:hover) {
            width: $mini-sidebar-width;

            .sub-menu,
            .dd-icon,
            .selected .sub-item {
                display: none;
                visibility: hidden;
            }

            .user-profile {
                .profile-img {
                    margin-left: 15px;
                }

                .profile-text {
                    visibility: hidden;
                }
            }

            .saperator span {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 40px;
                display: block;
            }

            .mat-mdc-list-item > .mdc-list-item__content a {
                padding-right: 0;
                padding-left: 0;
                text-align: center;
            }

            .mat-mdc-list-item > .mdc-list-item__content a > span:not(.menu-badge),
            .mat-mdc-list-item > .mdc-list-item__content a > .menu-caret {
                display: none;
            }

            .mat-mdc-list-item > .mdc-list-item__content a > span.menu-badge {
                position: absolute;
                top: 10px;
                right: 16px;
            }

            .mat-mdc-list-item > .mdc-list-item__content a > .material-icons {
                width: $mini-sidebar-width !important;
                padding: 0;
                margin: 0;
            }
        }
    }
    [dir='rtl'] {
        sbg-sidebar {
            display: block;
            overflow: hidden;
        }

        .minisidebar #snav.mat-sidenav ~ .mat-drawer-content {
            margin-left: 0px !important;
            margin-right: $mini-sidebar-width !important;
        }
    }
}

.sidebar-closed #snav {
    &.mat-sidenav ~ .mat-drawer-content {
        margin-left: 0px !important;
    }
}
