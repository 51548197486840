sbg-group-pending-transactions {
    sbg-table {
        sbg-group-pending-transactions-row-expanded {
            sbg-loading-container {
                .loading-container {
                    .loading-indicator {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
