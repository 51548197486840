//Scope global material styles to specific components
.sbg-account-views {
    mat-tab-header {
        .mat-ink-bar {
            background-color: var(--white-color) !important;
        }

        .mat-mdc-tab-label-container {
            background-color: var(--secondary-color);
        }

        .mat-mdc-tab-label-content {
            color: var(--white-color);
        }
    }
    .mat-mdc-tab-label {
        @include breakpoint(small_only) {
            padding: 0 16px;
        }
    }
}

mat-tab-group[mat-align-tabs='start'] {
    .mat-mdc-tab-list {
        flex-grow: unset;
    }
}

.mat-mdc-tab-labels {
    justify-content: center;
}
