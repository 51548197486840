.mat-table[multitemplatedatarows] {
    .mat-row {
        border-bottom-width: 0;
    }
}

.mat-paginator {
    padding: 8px;
}

.mat-mdc-table {
    .mat-mdc-row {
        &.mat-table-expanded-row {
            border-bottom-width: 1px;
            min-height: 0;
            overflow: hidden;

            .mat-table-expanded-row-detail {
                margin: var(--MEDIUM-MARGIN) 0;
            }
        }

        &:not(.mat-table-expanded-row):hover {
            background: whitesmoke;
        }

        &.highlighted {
            background: whitesmoke;
        }
    }

    .mat-mdc-footer-cell {
        padding: var(--MEDIUM-MARGIN);
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
}

.mat-sidenav-container {
    @extend %s-regular-mediumgrey;
}

mat-icon {
    vertical-align: middle;

    &.medium.medium {
        font-size: 18px;
        height: 18px;
        width: 18px;
        line-height: 18px;
    }

    &.small.small {
        font-size: 14px;
        height: 14px;
        width: 14px;
        line-height: 14px;
    }

    &.inverse {
        color: var(--white-color);
    }

    &.primary {
        color: var(--primary-color);
    }

    &.secondary {
        color: var(--light-grey-color);
    }
}

.mat-form-field-outline-start {
    border-radius: 8px 0 0 8px !important;
}

.mat-form-field-outline-end {
    border-radius: 0 8px 8px 0 !important;
}

.mat-form-field-type-sbg-autocomplete-nx .mat-form-field-infix .mat-icon {
    align-self: center;
}

.mat-form-field.mat-form-field {
    line-height: normal;

    .sbg-autocomplete-search {
        max-height: 1rem;
    }

    &:not(.mat-focused) .mat-form-field-label {
        color: var(--light-grey-color);
    }

    &.input-inverse {
        label {
            @extend %m-regular-white;

            &.mat-input-placeholder.mat-input-placeholder {
                @extend %m-regular-white;
            }
        }
    }

    &:not([floatplaceholder]) {
        &.mat-form-field-should-float {
            .mat-input-placeholder {
                font-size: 1rem; // angular material transforms this down to 12
            }
        }
    }

    &.prefixed-placeholder {
        .mat-input-placeholder-wrapper {
            left: -16px;
        }
    }
}

.mat-slider-horizontal {
    width: 100%;
}

.mat-checkbox {
    @extend %s-regular-mediumgrey;
}

.mat-option.mat-option,
.mat-select-value.mat-select-value {
    @extend %s-regular-mediumgrey;
}

.mat-select-panel .mat-option.mat-option {
    line-height: inherit;
}

.mat-ink-bar {
    height: 4px !important;
    background-color: var(--secondary-color) !important;
}

.mat-tab-header {
    background-color: var(--white-color);
    border-bottom: 1px solid var(--pale-grey-color) !important;
}

.mat-tab {
    &-group {
        overflow: hidden;
    }

    &-label,
    &-link {
        min-width: inherit;
        text-transform: uppercase;
        @extend %s-regular-mediumgrey;
        opacity: 1 !important;

        &-active {
            @extend %s-regular-primary;
        }
    }

    &-body-content.mat-tab-body-content {
        overflow: hidden;
    }
}

.mdc-linear-progress__buffer-bar {
    background-color: var(--pale-grey-color) !important;
}

snack-bar-container.success-snackbar {
    background-color: var(--positive-color);
}

.mat-mdc-dialog {
    &-container.mat-mdc-dialog-container {
        --mdc-dialog-subhead-color: var(--white-color);
        padding: 0 !important;
        display: block;
        border-radius: 2px;
    }

    &-title.mat-mdc-dialog-title {
        margin: 0 !important;
        padding: var(--MEDIUM-MARGIN);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @extend %xl-regular-white;
        background: var(--gradient-blue-01-diagonal);
        &.mdc-dialog__title::before {
            display: none;
        }

        &.compact {
            padding: var(--SMALL-MARGIN);
        }
    }

    &-content.mat-mdc-dialog-content.mdc-dialog__content {
        display: block;
        padding: var(--MEDIUM-MARGIN);
        margin: auto;
        @extend %s-regular-mediumgrey;
        --mdc-dialog-supporting-text-color: var(--medium-grey-color);
    }

    &-actions.mat-mdc-dialog-actions.mdc-dialog__actions {
        padding-top: var(--MEDIUM-MARGIN);
        padding-bottom: var(--SMALL-MARGIN);
        min-height: inherit;

        &.compact {
            padding-top: var(--SMALL-MARGIN);
            padding-bottom: var(--SMALL-MARGIN);
        }
    }

    &-actions:last-child {
        margin-bottom: 0 !important;
        justify-content: flex-end;
        padding-right: 16px;
    }
}

.cdk-overlay {
    max-width: 80%;
    width: 50%;
}

.mat-divider {
    border-top-color: var(--pale-grey-color) !important;
}

.mdc-button.mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: var(--white-color);
}
