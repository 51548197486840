@import 'breakpoint';
@import 'typography';
osx-button-container {
    gap: 20px !important;
    @include breakpoint(tablet) {
        width: 100%;
    }
}

evo-card-container {
    osx-button-container {
        flex-wrap: nowrap !important;
        @include breakpoint(mobile) {
            flex-wrap: wrap !important;
        }
        width: 100%;
    }
}

.os-button-container {
    &.standard-button {
        width: 220px;
        span {
            text-wrap: nowrap;
        }
        @include breakpoint(small_only) {
            width: 100%;
        }
        @container (max-width: 540px) {
            width: 100%;
        }
    }
    &.normal-button {
        @include breakpoint(small_only) {
            width: 100%;
        }
    }
}
